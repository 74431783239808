<!--指标库设置  -->
<template>
	<div>
		<div class="child-top">
			<div class="input-from">
				<el-select class="last-child" clearable placeholder="请选择状态" v-model="deviceSelect" @change="deviceChange">
					<el-option :label="'Eaglenos (血乳酸)'" :value="1"></el-option>
					<el-option :label="'Ls4 (乳酸盐)'" :value="2"></el-option>
					<el-option :label="'GymAware'" :value="3"></el-option>
				</el-select>

				<el-select class="last-child" clearable placeholder="请选择人员" v-model="personUuid" v-if="deviceSelect == 3" @change="deviceChange">
					<el-option v-for="(item, index) in personList" :label="item.athleteName" :value="item.athleteReference" :key="'person' + index"></el-option>
				</el-select>

				<el-date-picker v-model="searchDate" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" placeholder="筛选日期" v-if="deviceSelect == 3" @change="deviceChange"> </el-date-picker>
			</div>
			<div class="operation-button">
				<el-button @click="getData" class="new_btn" v-if="deviceSelect !== 3" round>
					<span>更新</span>
				</el-button>
				<el-button @click="getGymData" class="new_btn" v-if="deviceSelect == 3" round>
					<span>更新</span>
				</el-button>
				<el-button @click="delData" class="del_btn" v-if="deviceSelect == 3" round>
					<span>删除</span>
				</el-button>
			</div>
		</div>

		<div class="echarts_view" v-if="deviceSelect == 3">
			<div class="echarts_content echarts_content_top">
				<div class="echarts_title">Mean Power(W)</div>
				<div v-for="(item, index) in lineData" :id="'echartsLine' + index" :key="'echartsLine' + index" class=""></div>
			</div>
			<div class="echarts_content">
				<div class="echarts_title">Mean Velocity(m/s)</div>
				<div v-for="(item, index) in barData" :id="'echartsBar' + index" :key="'echartsBar' + index" class=""></div>
			</div>
		</div>

		<div class="table_view" style="background: #fff" v-if="deviceSelect !== 3">
			<el-table v-loading="tableLoading" class="content-test-table" :data="tableList" style="width: 100%" :key="'table1'">
				<el-table-column label="序号" prop="order_no" show-overflow-tooltip type="index" width="60" align="center">
					<template slot-scope="scope">{{ (page - 1) * 10 + scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column label="设备名称" prop="type" show-overflow-tooltip align="center">
					<template slot-scope="scope">
						{{ scope.row.type == 1 ? 'Eaglenos (血乳酸)' : 'Ls4 (乳酸盐)' }}
					</template>
				</el-table-column>
				<el-table-column label="检测项目" show-overflow-tooltip align="center">乳酸</el-table-column>
				<el-table-column label="测试时间" prop="year" show-overflow-tooltip align="center">
					<template slot-scope="scope">
						{{ scope.row.year + '-' + scope.row.month + '-' + scope.row.day + '  ' + scope.row.hour + ':' + scope.row.minute + ':' + scope.row.second }}
					</template>
				</el-table-column>
				<el-table-column label="监测值" prop="value" show-overflow-tooltip align="center">
					<template slot-scope="scope">
						<span style="color: #f00;">{{ scope.row.value }}</span> mmol/L
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				:current-page.sync="page"
				:page-size="page_size"
				:total="total"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>

		<div class="table_view" style="background: #fff" v-if="deviceSelect == 3">
			<el-table v-loading="tableLoading" class="content-test-table" :data="GymwareList" style="width: 100%" @selection-change="selectChange" :key="'table2'">
				<el-table-column label="Time" show-overflow-tooltip align="center" width="80">
					<template slot-scope="scope">{{ getTime(scope.row.recorded) }}</template>
				</el-table-column>
				<el-table-column label="Set" show-overflow-tooltip type="index" align="center" width="80">
					<template slot-scope="scope">{{ scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column label="Reps" show-overflow-tooltip align="center" width="80">
					<template slot-scope="scope">{{ scope.row.repCount }}</template>
				</el-table-column>
				<el-table-column label="Exercise" show-overflow-tooltip width="240" align="center">
					<template slot-scope="scope">{{ scope.row.exerciseName }}</template>
				</el-table-column>
				<el-table-column label="Mass(kg)" show-overflow-tooltip align="center" width="80">
					<template slot-scope="scope">{{ scope.row.barWeight }}</template>
				</el-table-column>
				<el-table-column label="Mean Velocity Average (m/s)" shom-overflow-tooltip align="center">
					<template slot-scope="scope">{{ scope.row.average_v }}</template>
				</el-table-column>
				<el-table-column label="Mean Velocity Best (m/s)" show-overflow-tooltip align="center">
					<template slot-scope="scope">{{ scope.row.best_v }}</template>
				</el-table-column>
				<el-table-column label="Mean Power Average (W)" show-overflow-tooltip align="center">
					<template slot-scope="scope">{{ scope.row.average_p }}</template>
				</el-table-column>
				<el-table-column label="Mean Power Best (W)" show-overflow-tooltip align="center">
					<template slot-scope="scope">{{ scope.row.best_p }}</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tableLoading: true, //loading
			tableList: [],
			deviceSelect: 1,
			page_size: 10,
			total: 0,
			page: 1,
			searchDate: new Date(),
			personList: [],
			personUuid: '',
			GymwareList: [],
			lineData: [],
			barData: [],
		}
	},
	mounted() {
		this.getData()

		this.$axios.post('/p/Gymware/staffLists').then(res => {
			if (res.data.code == 0) {
				this.personUuid = res.data.data[0].athleteReference
				this.personList = res.data.data
			}
		})
	},

	methods: {
		getGymData() {
			this.$axios.post('/p/Gymware/syncReps').then(res => {
				if (res.data.code == 0) {
				}
			})
		},
		delData() {
			this.$axios.post('/p/Gymware/delete').then(res => {
				if (res.data.code == 0) {
				}
			})
		},
		selectChange() {},
		setEchartsBar(index, items) {
			var cname = 'echartsBar' + index
			let myChart = this.$echarts.init(document.getElementById(cname))

			var cdata = items.chart_info[0].data,
				x_data = [],
				value_data = []
			for (var i in cdata) {
				x_data.push(i)
				value_data.push(cdata[i])
			}

			var options = {
				title: {
					left: 'center',
					// text: items.chart_info[0].type,
				},
				grid: {
					left: '10%',
					right: '3%',
					top: '10%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					// formatter: '{a}: {c}',
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				color: ['#0055e9'],

				series: [
					{
						data: value_data,
						type: 'bar',
						barWidth: 16,
						name: 'Mean Velocity(m/s)',
					},
				],
			}
			myChart.setOption(options)
		},
		setEchartsLine(index, items) {
			var cname = 'echartsLine' + index
			let myChart = this.$echarts.init(document.getElementById(cname))
			var cdata = items.chart_info[0].data,
				x_data = [],
				value_data = []
			for (var i in cdata) {
				x_data.push(i)
				value_data.push(cdata[i])
			}
			var options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				grid: {
					left: '15%',
					right: '3%',
					top: '20%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: false,
					},
				},
				yAxis: {
					type: 'value',
					// interval: 1,
					min: 'dataMin',
					// interval: 2,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
						// interval: 2,
					},
				},
				color: ['#0055e9'],
				series: [
					{
						name: 'Mean Power(W)',
						data: value_data,
						type: 'line',
						// symbol: 'circle',
						// symbolSize: 8,
						// borderWidth: 5,
						itemStyle: {
							borderColor: '#0055e9',
						},

						symbolSize: 10,
						symbol: 'circle',
						smooth: true,
						lineStyle: {
							// width: 3,
							// shadowColor: 'rgba(0,0,0,0.3)',
							// shadowBlur: 10,
							// shadowOffsetY: 8,
						},
					},
				],
			}
			myChart.setOption(options)
		},
		deviceChange() {
			this.page = 1
			if (this.deviceSelect == 3) {
				this.getGymwareData()
				// this.$nextTick(() => {
				// 	for (var i = 1; i < 7; i++) {
				// 		this.setEchartsBar(i)
				// 		this.setEchartsLine(i)
				// 	}
				// })
			} else {
				this.getData()
			}
		},
		setEcharts() {
			this.setEchartsBar(i)
			this.setEchartsLine(i)
		},
		//请求列表数据
		getData() {
			this.$axios
				.post(
					'/p/equipment/lactateLists',
					this.$qs({
						page: this.page,
						page_size: this.page_size,
						type: this.deviceSelect,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: '设备数据更新完成',
						})
						this.tableList = res.data.data.rows
						this.total = res.data.data.total
						this.tableLoading = false
						console.log(this.tableLoading)
					}
				})
		},
		// 请求gymware数据
		getGymwareData() {
			this.$axios
				.post(
					'/p/Gymware/info',
					this.$qs({
						athleteReference: this.personUuid,
						date: this.searchDate,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.GymwareList = res.data.data.rows
						this.lineData = res.data.data.power_chart
						this.barData = res.data.data.velocity_chart
						console.log(this.lineData)
						this.$nextTick(() => {
							console.log(this.lineData)
							this.lineData.forEach((item, index) => {
								this.setEchartsLine(index, item)
							})
							this.barData.forEach((item, index) => {
								this.setEchartsBar(index, item)
							})
						})
					}
				})
		},

		handleCurrentChange(val) {
			this.page = val
			this.getData()
		},

		getTime(value) {
			const date = new Date(value * 1000)
			const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
			const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
			// console.log(hours, minutes)
			return hours + ':' + minutes
		},
	},
}
</script>
<style lang="scss" scoped>
.child-top .el-date-editor {
	border: none;
}
.disColor {
	color: #ccc !important;
}

.text {
	display: inline-block;
	margin-left: 20px;
}

/deep/.el-dialog__title {
	color: #303133;
	font-weight: 700;
}

/deep/.el-input {
	position: relative;
	font-size: 0.72917rem;
	display: inline-block;
	width: 222px;
}

/deep/.el-table th > .cell {
	padding-left: 15px;
}
/deep/.el-pagination__editor.el-input {
	width: 50px;
}

.child-top {
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}

.el-pagination {
	padding-bottom: 10px;
}

.echarts_view {
	overflow-x: auto;
	box-sizing: border-box;
	overflow-y: hidden;
	margin-bottom: 20px;
}

.echarts_content {
	width: 100%;
	height: 300px;
	white-space: nowrap;
	box-sizing: border-box;
	// margin-bottom: 1px;
	div {
		display: inline-block;
		background: #fff;
		margin-right: 10px;
		width: 400px;
		height: 300px;
		box-sizing: border-box;
	}
	.echarts_title {
		width: auto;
		padding: 0 20px;
		text-align: center;
		// line-height: 300px;
		// height: 20px;
		// transform: rotate(-90deg);
		writing-mode: vertical-lr;
		margin-right: 0;
	}
}

.echarts_content_top {
	height: 200px;
	div {
		height: 200px;
	}
}

.table_view {
	margin-bottom: 20px;
}
</style>
